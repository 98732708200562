import {
  Button,
  Center,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ModalConfirm from "components/Modal/ModalConfirm";
import ModalSellNFTHunter from "../modalSell";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailHunterNFT } from "api/hunterNFTs.api";
import { cancelSellProductNFTs } from "api/products.api";
import useWithToast from "hooks/useWithToast";
import { RARITY } from "constants/constants";
import DtCommonBg from 'assets/img/card/dt-common-bg.png'
import DtUncommonBg from 'assets/img/card/dt-uncommon-bg.png'
import DtRareBg from 'assets/img/card/dt-rare-bg.png'
import DtLegendaryBg from 'assets/img/card/dt-legend-bg.png'
import DtEpicBg from 'assets/img/card/dt-epic-bg.png'
import InformationNFT from "./informationNFT";
import ButtonType from "components/Button";

export default function DetailNFTsGenesis() {
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const { id } = useParams();
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  
  const {
    isOpen: isOpenSell,
    onOpen: onOpenSell,
    onClose: onCloseSell,
  } = useDisclosure();

  const {
    isOpen: isOpenCancelSell,
    onOpen: onOpenCancelSell,
    onClose: onCloseCancelSell,
  } = useDisclosure();

  const IconCard = useMemo(() => {
    const rarity = dataDetail?.rarity;
    let iconCard = {
      // iconBox: Images.COMMON_BOX_ICON,
      // iconRarity: Images.RARITY_COMMON_ICON,
      iconBg: DtCommonBg,
      bgRarity: '#545f7b',
    };
    if (rarity === RARITY.RARITY_UNCOMMON) {
      iconCard = {
        // iconBox: Images.UNCOMMON_BOX_ICON,
        // iconRarity: Images.RARITY_UNCOMMON_ICON,
        iconBg: DtUncommonBg,
        bgRarity: '#008a17',
      };
    } else if (rarity === RARITY.RARITY_RARE) {
      iconCard = {
        // iconBox: Images.RARE_BOX_ICON,
        // iconRarity: Images.RARITY_RACE_ICON,
        iconBg: DtRareBg,
        bgRarity: '#005ddd',
      };
    } else if (rarity === RARITY.RARITY_EPIC) {
      iconCard = {
        // iconBox: Images.EPIC_BOX_ICON,
        // iconRarity: Images.RARITY_EPIC_ICON,
        iconBg: DtEpicBg,
        bgRarity: '#7612ff',
      };
    }
    if (rarity === RARITY.RARITY_LEGENDARY) {
      iconCard = {
        // iconBox: Images.LEGENDARY_BOX_ICON,
        // iconRarity: Images.RARITY_LEGENDARY_ICON,
        iconBg: DtLegendaryBg,
        bgRarity: '#ffd32f',
      };
    }

    return iconCard;
  }, [dataDetail?.rarity]);

  const getDetail = async (id) => {
    try {
      setIsLoading(true);
      const { data } = await getDetailHunterNFT(id);
      setDataDetail(data?.data);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDetail(id);
    // getRate();
  }, [id]);

  const handleCancelSell = async () => {
    try {
      const res = await cancelSellProductNFTs(dataDetail?.product_model?.id);
      if (res) {
        handleSellDetailSuccess(0);
        showToastSuccess({
          title: "Cancel sell successfully!",
        });
        onCloseCancelSell();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const isSell = useMemo(() => {
    return dataDetail?.product_model?.status === "SALE";
  }, [dataDetail?.product_model?.status]);

  const handleSellDetailSuccess = (price) => {
    // setDataDetail((prev) => {
    //   const productModel = { ...prev?.product_model };
    //   return {
    //     ...prev,
    //     product_model: {
    //       ...productModel,
    //       status: productModel?.status === "NEW" ? "SALE" : "NEW",
    //       price: price,
    //     },
    //   };
    // });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Item Detail
            </Text>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" pt="24px" justifyContent="center">
          {isLoading ? (
            <Center minH="200px" m="auto">
              <LoadingSpinner />
            </Center>
          ) : (
            <Flex
              flexDirection="column"
              alignItems="center"
              gap="20px"
              backgroundImage={IconCard?.iconBg}
              backgroundPosition='center'
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              padding="24px"
              borderRadius="8px"
              w="80%"
            >
              {dataDetail && <InformationNFT item={dataDetail} IconCard={IconCard}/>}
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                h="44px"
                w="auto"
                btnType="cancel-sell"
                onClick={() => {
                  if (isSell) {
                    onOpenCancelSell();
                  } else {
                    onOpenSell();
                  }
                }}
              >
                {isSell ? "Cancel Sell" : "Sell"}
              </ButtonType>
            </Flex>
          )}
        </CardBody>
      </Card>
      <ModalConfirm
        isOpen={isOpenCancelSell}
        onClose={onCloseCancelSell}
        onSubmit={handleCancelSell}
        title="Cancel Sell"
        description={"Are you sure you want to cancel sell this NFT?"}
      />
      {isOpenSell && (
        <ModalSellNFTHunter
          isOpen={isOpenSell}
          itemSell={dataDetail}
          onClose={onCloseSell}
          handleSellDetailSuccess={handleSellDetailSuccess}
        />
      )}
    </Flex>
  );
}