const END_POINT = {
  LOGIN: "auth/login",
  REGISTER: "auth/register",
  LOGOUT: "auth/logout",
  CHANGE_PASS: "users/change-password",
  RESET_PASSWORD: "auth/reset-password",
  DASHBOARD: "admin/dashboard",

  // users
  USER_PROFILE: "users/me",
  GET_USER: "users",
  POST_USER: "users",
  GET_USER_BY_ID: "users",
  PUT_USER_BY_ID: "users",
  DELETE_USER_BY_ID: "users",

  // health-check
  GET_HEALTH_CHECK: "health-check",

  // machine
  GET_MACHINE: "machines",
  POST_MACHINE: "machines",
  GET_MACHINE_BY_ID: "machines",
  PUT_MACHINE_BY_ID: "machines",
  DELETE_MACHINE_BY_ID: "machines",

  // play fee
  GET_PLAY_FEES: "play-fees",
  POST_PLAY_FEES: "play-fees",
  GET_PLAY_FEES_BY_ID: "play-fees",
  PUT_PLAY_FEES_BY_ID: "play-fees",
  DELETE_PLAY_FEES_BY_ID: "play-fees",

  // role
  ROLE: "roles",
  RULE: "rules",

  // Revenue sharing
  GET_REVENUE_SHARINGS: "revenue-sharings",
  POST_REVENUE_SHARING: "revenue-sharings",
  GET_REVENUE_SHARING_BY_ID: "revenue-sharings",
  PUT_REVENUE_SHARING_BY_ID: "revenue-sharings",
  DELETE_REVENUE_SHARING_BY_ID: "revenue-sharings",

  // project-categories
  GET_PROJECT_CATEGORY: "project-categories",
  POST_PROJECT_CATEGORY: "project-categories",
  GET_PROJECT_CATEGORY_BY_ID: "project-categories",
  PUT_PROJECT_CATEGORY_BY_ID: "project-categories",
  DELETE_PROJECT_CATEGORY_BY_ID: "project-categories",

  // prize
  GET_PRIZE: "prizes",
  POST_PRIZE: "prizes",
  GET_PRIZE_BY_ID: "prizes",
  PUT_PRIZE_BY_ID: "prizes",
  DELETE_PRIZE_BY_ID: "prizes",
  TYPES_PRIZE: "prizes/types",
  POST_UPLOAD_IMAGE_PRIZE: "upload",

  // referral reward
  GET_REFERRAL_REWARD: "referral-rewards",
  POST_REFERRAL_REWARD: "referral-rewards",
  GET_REFERRAL_REWARD_BY_ID: "referral-rewards",
  PUT_REFERRAL_REWARD_BY_ID: "referral-rewards",
  DELETE_REFERRAL_REWARD_BY_ID: "referral-rewards",

  // reward
  GET_REWARD: "rewards",
  POST_REWARD: "rewards",
  GET_REWARD_BY_ID: "rewards",
  PUT_REWARD_BY_ID: "rewards",
  DELETE_REWARD_BY_ID: "rewards",

  GET_lIST_ROLE: "admin/roles",
  ADD_ROLE: "admin/addRole",
  EDIT_ROLE: "admin/editRole",
  DELETE_ROLE: "admin/deleteRole",

  COURSE_LIST: "admin/",
  COURSE: "admin/",
  CREATE_COURSE: "admin/",
  UPDATE_COURSE: "admin/",

  GET_MACHINE_SETTINGS: "machines",
  GAME_MODE: "game-mode",
  SKILL_RANK: "skill-rank",
  LEVEL_REQUIRED: "level-required",
  EVENT: "events",
  EVENT_RULE: "events/rules",
  EVENT_WHITE_LIST: "events/whitelist",
  USER_EVENT: "users/event",
  CRANE_MACHINES: "crane-machines",
  SYSTEM_TICKETS: "system-tickets",
  ROOM_HISTORIES: "room-histories",

  GAMEPLAY_TYPE: "gameplay-types",

  // cabinet type
  CABINET_TYPE_TYPE: "cabinet-type",

  // maintenance
  GET_MAINTENANCE: "system-maintenances",
  POST_MAINTENANCE: "system-maintenances",
  GET_MAINTENANCE_BY_ID: "system-maintenances",
  PUT_MAINTENANCE_BY_ID: "system-maintenances",
  DELETE_MAINTENANCE_BY_ID: "system-maintenances",

  // nfts hunter
  PRODUCT_HUNTER: "product/hunters",
  PRODUCT: "product",
  PRODUCT_SELL_MULTIPLE: "product/sell-multiple",
  PRODUCT_SELL_SINGLE: "product/sell-single",
  PRODUCT_CANCEL_SELL: "product/cancel-sell",

  NFT_CONTRACT: "nft-contract",
  NFT_HUNTER: "nft-hunter",
  HUNTER_OFF_CHAIN: "hunter-off-chain",
  NFT_GAUNTLET: "nft-gauntlet",
  NFT_BOUNTY_BALL: "nft-bounty-ball",

  // rate
  EXCHANGE_RATE: "exchange-rate",
  EXCHANGE_RATE_CURRENCY: "exchange-rate/currency",

  // admin
  GET_ADMIN: "users/admin",
  CREATE_ADMIN: "users",
  UPDATE_ADMIN: "users/change-admin-role",

  // wallet
  WALLET: "master-public-key"
};

export default END_POINT;
